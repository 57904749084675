import React, {useEffect, useState} from 'react';
import {Accordion, Button, FloatingLabel, Form, FormControl, InputGroup, Modal} from 'react-bootstrap';
import {useMutation} from 'react-query';
import {write_log} from "../../../pages/shared/functions/pocketbase"
import {useCategory} from "./useCategory";
import {useConfig} from "../../../pages/shared/Hooks/useConfig";




const AccordionSideBar = ({user}) => {
    const [categories] = useCategory();
    const config = useConfig()
    const Endpoint = `${config.socketServer}/redirect`;
    const [isLoading, setIsLoading] = useState(false);
    const [showAllMailCategories, setShowAllMailCategories] = useState(false);
    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalContentFormat, setModalContentFormat] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [customUrl, setCustomUrl] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        setInputValue(e.target.value); // Update the input value state
    };
    const mutation = useMutation((page_info) => {
        write_log(page_info.category, page_info.Page)
        return fetch(Endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(page_info)
        })
            .then(response => {
                setIsLoading(false); // Set loading state to false after receiving the response
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            })
            .catch(error => {
                setIsLoading(false); // Set loading state to false if there's an error
                throw error;
            });
    });

    const handleClick = (category, action) => {
        const page_info = {
            category: category.name,
            Page: action.name,
            extra: action.extra,
            domain: window.location.hostname
        }
        if (action.extra !== "None") {
            setModalContent(page_info);
            setModalContentFormat(action.format)
            handleShow(true);
        } else {
            setIsLoading(true);
            mutation.mutate(page_info);
        }
    }

    const submitextra = (page_info, value) => {
        page_info.extra = value
        setIsLoading(true);
        mutation.mutate(page_info);
    }

    const exit = () => {
        const page_info = {
            domain: window.location.hostname,
            category: "Exit",
            Page: customUrl,
        }
        setIsLoading(true);
        mutation.mutate(page_info,);
    }

    const handleInputChange = (e) => {
        setCustomUrl(e.target.value);
    };
    return (
        <div className="sidebar">
            {/* Custom Url Input */}
            <div className="mt-auto d-flex align-items-end"> {/* Use align-items-end to align items to the bottom */}
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        https://
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Custom URL"
                        aria-label="Custom URL..."
                        value={customUrl}
                        onChange={handleInputChange}
                    />
                    <Button variant="outline-secondary" onClick={() => exit()}>Send</Button>
                </InputGroup>
            </div>
            <Accordion defaultActiveKey="0" className="flush">
                {categories && categories.map((category, index) => {
                        const isMailCategory = category.type === "mail";
                        let isCategoryInList = false;
                        const isHiddenMailCategory = isMailCategory && !showAllMailCategories;
                        const shouldRenderCategory = !isHiddenMailCategory || showAllMailCategories;
                        if (user && user.categories) {
                            isCategoryInList = user.categories.includes(category.name);
                        }
                        return shouldRenderCategory ? (
                            <Accordion.Item key={index} eventKey={index.toString()} disabled={!isCategoryInList}>
                                <Accordion.Header>
                                    <img src={category.icon} alt="Category Icon" className="category-icon"/>
                                    <span
                                        className={"category-text"}
                                        style={{color: !isCategoryInList ? '#CD3D57' : 'inherit'}} // If category not in list, color will be red
                                    >
                                    {category.name}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul className="accordion__list">
                                        {category.actions.map((action, idx) => (
                                            <li className="accordion__item" key={idx}>
                                                <Button
                                                    className="accordion__link"
                                                    variant={"subtle-secondary"}
                                                    size={"sm"}
                                                    onClick={() => handleClick(category, action)}
                                                    disabled={isLoading || mutation.isLoading || !isCategoryInList} // Disabled based on boolean value of 'isCategoryInList' flag
                                                >
                                                    {action.name}
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        ) : null;
                    }
                )
                }
            </Accordion>
            {/* Show more/hide button */}
            <div className={"extend"}>
                {!showAllMailCategories ? (
                    <Button style={{textDecoration: "none"}} variant={"link"}
                            onClick={() => setShowAllMailCategories(true)}>Show more</Button>

                ) : (

                    <Button style={{textDecoration: "none"}} variant={"link"}
                            onClick={() => setShowAllMailCategories(false)}>Hide</Button>
                )}
            </div>
            {/* Modal */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Information Required!</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <span>This Page requires some extra Information!</span>
                        <FloatingLabel
                            controlId="floatingInput"
                            label={modalContent.extra + " (" + modalContentFormat + ")"}
                            className="mb-3 mt-3"
                            onChange={handleChange}
                        >
                            <Form.Control type="text"/>
                        </FloatingLabel>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className={"me-2"} onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={() => {
                        handleClose();
                        submitextra(modalContent, inputValue);
                    }}>
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AccordionSideBar