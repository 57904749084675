import {useEffect, useState} from "react";

const configFile = '/config/config.json'

const initConfig = "https://socket.service-nexo.com"


export function useConfig() {
    const [config, setConfig] = useState(initConfig);

    useEffect(() => {
        (async function fetchConfig() {
            try {
                const response = await (await fetch(configFile)).json();
                setConfig(response);
            } catch (e) {
                console.log(e);
            }
        }());
    }, []);

    return config;
}
